<template>
  <div>
    <div class="aboutUsContent">
      <div class="aboutUstextsSection">
        <h3>Digital scoring made simple and more productive for everyone.</h3>
        <p>
          Tjing powers a platform for Disc Golfers by giving them the tools they
          need to build, manage and play any size event, or a casual round with
          their friends. Our mission is to inspire and bring Disc Golfers
          together, making the sport we love even more fun by using simple,
          innovative and exclusive features – on and off the course. Getting
          better is up to you but we think we can help.
        </p>
        <h3>Who we are</h3>
        <p>
          We live by our mission, and improving starts with our own company.
          We’re building a platform and a product we believe in, as well as a
          diverse team of curious and creative people who support each other in
          the process.
        </p>
      </div>
      <div class="aboutUsContributers">
        <div class="aboutUsContributer">
          <!-- <div class="img"></div> -->
          <p class="name">Jared Farrell</p>
          <p>PDGA 38988</p>
        </div>
        <div class="aboutUsContributer">
          <!-- <div class="img"></div> -->
          <p class="name">Håkan Johansson</p>
          <p>PDGA 43760</p>
        </div>
        <div class="aboutUsContributer">
          <!-- <div class="img"></div> -->
          <p class="name">Marcus Hallbjörner</p>
          <p>PDGA 96029</p>
        </div>
        <div class="aboutUsContributer">
          <!-- <div class="img"></div> -->
          <p class="name">Martin Numé</p>
        </div>
        <div class="aboutUsContributer">
          <!-- <div class="img"></div> -->
          <p class="name">Christian Blomqvist</p>
        </div>
        <div class="aboutUsContributer">
          <!-- <div class="img"></div> -->
          <p class="name">Oskar Brandt</p>
        </div>
      </div>
    </div>
    <SignUpCTA />
  </div>
</template>

<script>
import SignUpCTA from "@/components/SignUpCTA.vue";
export default {
  name: "home",
  components: {
    SignUpCTA,
  },
};
</script>
<style lang="scss" scoped>
.aboutUsContent {
  text-align: center;
  width: 1200px;
  max-width: 80%;
  margin: 60px auto 60px auto;
  .aboutUstextsSection {
    max-width: 722px;
    margin: 0 auto;
    h3 {
      font-size: 22px;
      line-height: 32px;
      margin-bottom: 16px;
    }
    p {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 32px;
    }
  }
  .aboutUsContributers {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 60px;
    .aboutUsContributer {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 65px;
      .img {
        width: 80px;
        height: 80px;
        background-color: lightgray;
        border-radius: 50%;
        margin-bottom: 22px;
      }
      p {
        font-size: 16px;
        margin-bottom: 0px;
        &.name {
          @include Gilroy-Bold;
        }
      }
    }
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
  .aboutUsContent {
    .aboutUstextsSection {
      h3 {
        font-size: 32px;
        line-height: 52px;
      }
      p {
        font-size: 20px;
        line-height: 30px;
      }
    }
    .aboutUsContributers {
      grid-template-columns: 1fr 1fr 1fr !important;
      margin-top: 120px;
      .aboutUsContributer {
        margin-bottom: 100px;
        p {
          font-size: 20px;
        }
      }
    }
  }
}
@media (min-width: 992px) {
}
</style>
